import 'core-js';

import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';

export default class {
	constructor() { 
		app.commons = app.commons || {}, app.commons.Pageloader = this;
		barba.use(barbaPrefetch);
		this.init();
	}
	init() {
		barba.init({
			debug: false,
			timeout: 10000,
			preventRunning: true,
			transitions: [{
				name: 'pageloader',
				async leave(data) {
					if(!document.body.classList.contains('previewIsClicked')) {
						app.commons.Global.pageContainer.querySelectorAll('[pageloader]').forEach($elm => {
							if(
								app.commons.Global.isBreakpoint
								|| (app.commons.Scroller.getScrollPos() + window.innerHeight > $elm.getBoundingClientRect().top + 15)) {
								$elm.setAttribute('pageloader-hide','');
							}
						});
						await app.commons.Pageloader.resolveAfter();
					}
				},
				afterLeave: ({ current, next, trigger }) => {
					app.commons.Scroller.destroy(true);
	            },
				after: ({ current, next, trigger }) => {
					//app.commons.Preloader.load([...app.commons.Global.pageContainer.querySelectorAll('.section__project--current img')], app.commons.Pageloader.pageParse, [current, next, trigger]);
					app.commons.Preloader.load([...app.commons.Global.pageContainer.querySelectorAll('.section__project--current img')]);
					setTimeout(() => {this.pageParse(current, next, trigger); }, 100);
				},
				beforeEnter({ next }) {
					const js = next.container.querySelectorAll('main script');
				    if(js != null) { js.forEach((item) => eval(item.innerHTML)); }
				    app.commons.Nav.langs();
			    },
				once: ({ current, next, trigger }) => {
					this.pageshow(true, next.url.href);
					//if(next.namespace == 'projects') { app.sections.Projects.scrollTo(); }
				}
			}]
		});
	}
	pageParse(current, next, trigger) {
		app.init();
		if(app.commons.Global.isBreakpoint) { window.scrollTo(0, 0); }
		app.commons.Pageloader.pageshow(false, next.url.href);
		//if(next.namespace == 'projects') { app.sections.Projects.scrollTo(); }
	}
	pageshow(init=false,url=false) {
		const isPreviewIsActive = document.body.classList.contains('previewIsClicked'),
			$currentProject = app.commons.Global.pageContainer.querySelector('.section__project--current'),
			$currentProjectItems = $currentProject ? $currentProject.querySelectorAll('.section__project--current .block__project__items__item') : false,
			$currentProjectItemsFirst = $currentProjectItems ? $currentProjectItems[0] : false,
			$currentProjectItemsFirstHeader = $currentProject ? $currentProject.querySelector('.section__project__header') : false,
			$currentProjectItemsFirstImages = $currentProject ? $currentProject.querySelector('.section__project__images') : false;

		app.commons.Scroller.refresh();
		this.$elm = init ? this.$elm = document.querySelectorAll('[pageloader]') : app.commons.Global.pageContainer.querySelectorAll('[pageloader]');

		if($currentProjectItemsFirstImages) {
			$currentProjectItemsFirstImages.querySelectorAll('.lazy').forEach($img => {
				app.commons.Lazy.load($img);
			});
		}

		if($currentProjectItemsFirst && isPreviewIsActive) {
			app.commons.Scroller.stop();
			
			$currentProjectItemsFirstHeader.removeAttribute('pageloader-fadetop');
			$currentProjectItemsFirstHeader.setAttribute('pageloader-fadeleft','');
			$currentProjectItemsFirstImages.removeAttribute('pageloader');
			$currentProjectItems.forEach($elm => $elm.setAttribute('pageloader-delay','0ms'));
			$currentProjectItemsFirst.removeAttribute('pageloader-fadeleft');
			$currentProjectItemsFirst.setAttribute('pageloader-fade','');
		}

		setTimeout(() => {
			document.body.classList.remove('previewIsActive');
			if($currentProjectItemsFirst && isPreviewIsActive ) {
				if($currentProjectItemsFirst) {
					setTimeout(() => {
						$currentProjectItemsFirstHeader.removeAttribute('pageloader-fadeleft');
						$currentProjectItemsFirstHeader.setAttribute('pageloader-fadetop','');
						$currentProjectItemsFirstImages.setAttribute('pageloader','');
						$currentProjectItems.forEach($elm => {
							const delay = '600ms';
							$elm.setAttribute('pageloader-delay',delay);
							$elm.style.transitionDelay = delay;
						});
						$currentProjectItemsFirst.removeAttribute('pageloader-fade');
						$currentProjectItemsFirst.setAttribute('pageloader-fadeleft','');

						app.commons.Nav.leave();
						app.commons.Scroller.start();
					}, app.commons.Global.delay);
				}
			}

			this.$elm.forEach($elm => {
				const delay = $elm.getAttribute('pageloader-delay');
				if(delay !== '') { $elm.style.transitionDelay = delay; }
				$elm.setAttribute('pageloader-show','');
			});
		}, app.commons.Global.delay*0.5);
	}
	resolveAfter() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, app.commons.Global.delay);
		});
	}
}