import 'core-js';

import barba from '@barba/core';

import { gsap } from 'gsap';

export default class {
	constructor() {
		app.sections = app.sections || {}, app.sections.Project = this;
	}
	init() {
		const isSingle = document.body.classList.contains('single-projects');
		this.$elm = document.querySelectorAll('.section__project');
		this.$elm.forEach($elm => {
			const colorTxt = $elm.getAttribute('scroller-colors-txt'),
			colorBg = $elm.getAttribute('scroller-colors-bg');

			if($elm.classList.contains('section__projects__others') || Boolean($elm.closest('.section__projects__others'))) {
				return;
			}

			$elm.addEventListener('click', (evt) => {
				if(document.querySelector('.home') && app.commons.Global.isBreakpoint) {
					barba.go($elm.getAttribute('project-url'), 'click'); 
				}
			});

			if(isSingle && colorTxt && colorBg) {
				app.commons.Colors.set(colorTxt, colorBg);
			}

			if(document.querySelector('.single-projects')) {
				$elm.querySelectorAll('.block__project__items__item img').forEach($el => {
					$el.addEventListener('click', evt => {
						if(!app.commons.Global.isBreakpoint) {
							let $popup = document.createElement('div'),
								$close = document.createElement('div'),
								$content = document.createElement('div'),
								$contentAlign = document.createElement('div'),
								$contentPosition = document.createElement('div'),
								$imgWrap = document.createElement('div'),
								$img = $el.cloneNode();

							$img.classList.remove('lazy');
							$imgWrap.classList.add('popup-zoom__content__wrap');
							$popup.classList.add('popup-zoom');
							$close.classList.add('popup-zoom__close');
							$content.classList.add('popup-zoom__content');
							$contentAlign.classList.add('popup-zoom__content__align');
							$contentPosition.classList.add('popup-zoom__content__align__position');

							$img.style.opacity = 0;
						
							$imgWrap.append($img);
							$contentPosition.append($imgWrap);
							$contentAlign.append($contentPosition);
							$content.append($contentAlign);
							$popup.append($close,$content);
							document.body.append($popup);

							if(app.commons.Scroller.locomotive) app.commons.Scroller.locomotive.stop();

							gsap.fromTo($popup, { opacity: 0 }, { opacity: 1, duration: 0.3, ease: 'power1.in', onComplete: () => {
								gsap.fromTo($img, { opacity: 0, y: 30 }, { opacity: 1, y: 0, duration: 0.6, ease: 'power1.out' });
							} });
							
							$popup.addEventListener('click', evt => {
								if(evt.target.tagName !== 'IMG') {
									gsap.to($close, { opacity: 0, duration: 0.6, ease: 'power1.out' });
									gsap.to($img, { opacity: 0, y: -30, duration: 0.6, ease: 'power1.out', onComplete: () => {
										gsap.to($popup, { opacity: 0, duration: 0.3, ease: 'power1.in', onComplete: () => {
											$popup.remove();
											if(app.commons.Scroller.locomotive) app.commons.Scroller.locomotive.start();
										} });
									} });
								}
							});
						}
					});
				});
			}
		});
	}
}