import 'core-js';

export default class {
	constructor() {
		app.sections = app.sections || {}, app.sections.Projects = this;
	}
	init() {
		this.$elm = document.querySelector('.section__home-projects');

		this.resize();
		window.addEventListener('resize', () => { this.resize(); });
	}
	resize() {
		if(this.$elm) {
			let coorsRoot = this.$elm.querySelector('.row--root').getBoundingClientRect().width,
				$firstBlock = this.$elm.querySelector('.block__project'),
				padingLeft = window.innerWidth - ((window.innerWidth - coorsRoot) /2) - app.commons.Global.padd;
			
			$firstBlock.style.paddingLeft = `${padingLeft}px`;
			app.commons.Scroller.refresh();
		}
	}
}