import 'core-js';

import Global from "./commons/global.js";
import Pageloader from "./commons/pageloader.js";
import Scroller from "./commons/scroller.js";
import Colors from "./commons/colors.js";
import Lazy from "./commons/lazy.js";
import Preloader from "./commons/preloader.js";
import Nav from "./commons/nav.js";
import Header from "./sections/header.js";
import Project from "./sections/project.js";
import Projects from "./sections/projects.js";
import HomeProjects from "./sections/home-projects.js";
import Contact from "./sections/contact.js";
import TeamNav from "./sections/team-nav.js";
import Text from "./blocks/text.js";

(window.app = {}), (window => {
	const app = window.app || {};
	document.addEventListener('DOMContentLoaded', () => {
		new Global();
		new Pageloader();
		new Colors();
		new Scroller();
		new Lazy();
		new Preloader();
		new Nav();
		// init after
		new Header();
		new Project();
		new Projects();
		new HomeProjects();
		new Contact();
		new TeamNav();
		new Text();
		(app.init = () => {
			app.commons.Global.pageContainer = document.querySelector('[data-scroll-container]');
			app.commons.Colors.reset();
			app.commons.Lazy.init();
			app.commons.Scroller.init();
			setTimeout(() => {
				Object.keys(app).forEach(obj => {
					if([
						'components',
						'sections',
						'blocks'
					].includes(obj)) {
						Object.keys(app[obj]).forEach(classs => {
							if(classs !== 'Scroller') {
								app[obj][classs].init();
							}
						});
					}
				});
				document.body.classList.add('is-loaded');
			}, 0);

			// Autoplay videos
			const videoElms = document.querySelectorAll('video');
			videoElms.forEach((vid) => {
				vid.play();
			});
		})();
	});
})(window);