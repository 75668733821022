import 'core-js';

export default class {
	constructor() {
		app.commons = app.commons || {}, app.commons.Colors = this;
		this.colorIntTxt = getComputedStyle(document.documentElement).getPropertyValue('--colorIntTxt');
		this.colorIntBg = getComputedStyle(document.documentElement).getPropertyValue('--colorIntBg');
		//this.colorTxt = localStorage.getItem('colorTxt') ? localStorage.getItem('colorTxt') : this.colorIntTxt;
		//this.colorBg = localStorage.getItem('colorBg') ? localStorage.getItem('colorBg') : this.colorIntBg;
		this.colorTxt = this.colorIntTxt;
		this.colorBg = this.colorIntBg;
		this.reset();
		window.addEventListener('resize', () => { this.resize(); });
	}
	set(txt, bg) {
		if(txt) { 
			document.documentElement.style.setProperty('--colorTxt', txt); 
			localStorage.setItem('colorTxt', txt);
			localStorage.setItem('memColorTxt', txt);
		}
		if(bg) { 
			document.documentElement.style.setProperty('--colorBg', bg); 
			localStorage.setItem('colorBg', bg);
			localStorage.setItem('memColorBg', bg);
		}
		// document.documentElement.style.setProperty('--cursor', "url(\"data:image/svg+xml,"+this.cursor(txt)+"\")");
	}
	reset(txt=false, bg=false) {
		if(txt) { 
			this.colorTxt = txt;
			localStorage.setItem('colorTxt', txt);
		}
		if(bg) { 
			this.colorBg = bg; 
			localStorage.setItem('colorBg', bg);
		}
		document.documentElement.style.setProperty('--colorTxt', this.colorTxt);
		document.documentElement.style.setProperty('--colorBg', this.colorBg);
		// document.documentElement.style.setProperty('--cursor', "url(\"data:image/svg+xml,"+this.cursor(this.colorTxt)+"\")");
	}
	cursor(color) {
		let svg = '<svg xmlns="http://www.w3.org/2000/svg" width="15.2" height="32" viewBox="0 0 15.2 32">\
			<path d="M0,.19V3.1a.19.19,0,0,0,.14.18L3,4.32,7.19,5.91l.59.22.16.06,0,0a.17.17,0,0,1,0,.31l-.08,0-.45.17-4,1.52L.2,9.45l-.06,0A.21.21,0,0,0,0,9.66v3.78a.2.2,0,0,0,.14.19l1.14.42,2.72,1L7.26,16.3l2.77,1,.88.33.36.13a.19.19,0,0,1,0,.32l-.12,0-.63.24L8,19.34,4.78,20.55,1.83,21.66l-1.61.61-.08,0a.19.19,0,0,0-.14.18v3.76a.2.2,0,0,0,.14.19L1.62,27l3.53,1.33,4.29,1.61,3.69,1.39,1.22.45a5.77,5.77,0,0,0,.58.22h0a.2.2,0,0,0,.25-.19V28.9a.2.2,0,0,0-.15-.19l-1.13-.42-2.71-1L8,26.06,5.25,25l-.85-.32a3.21,3.21,0,0,1-.33-.12.25.25,0,0,1,0-.45l.1,0,.61-.23L7.22,23l3.21-1.21,2.94-1.1L15,20l.07,0a.19.19,0,0,0,.15-.18V16.1a.19.19,0,0,0-.15-.19l-1.14-.43-2.7-1L8,13.24l-2.73-1-.86-.32A2.81,2.81,0,0,1,4,11.77a.25.25,0,0,1,0-.44l.1,0,.61-.23,2.46-.92,3.21-1.21,2.94-1.1L15,7.22l.07,0A.19.19,0,0,0,15.2,7V4.1A.2.2,0,0,0,15,3.91l-1.33.5a2.29,2.29,0,0,1-1.59,0l-.13,0L9.64,3.51,5.86,2.09,2.21.72.93.24C.73.17.43.06.27,0S0,0,0,.19Z" fill="'+color+'"/>\
		</svg>';
		return svg.replace(/"/g, `'`).replace(/>\s{1,}</g, `><`).replace(/\s{2,}/g, ` `).replace(/[\r\n%#()<>?[\\\]^`{|}]/g, encodeURIComponent);
	}
	random() {
		let max = app.commons.Global.colors.length,
			rand = app.commons.Global.currentColors;
		do {
			rand = Math.floor(Math.random() * Math.floor(max));
		} while(rand === app.commons.Global.currentColors);
		
		let color  = app.commons.Global.colors[rand];
		this.set(color['text_color'], color['background_color']);
		this.reset(color['text_color'], color['background_color']);
		app.commons.Global.currentColors = rand;
	}
	resize() {
		//if(this.isBreakpoint) this.reset(this.colorIntTxt, this.colorIntBg);
	}
}