import 'core-js';

export default class {
	constructor() {
		app.blocks = app.blocks || {}, app.blocks.Text = this;
	}
	init() {
		this.$elm = document.querySelectorAll('.block__text');
		this.isGeneric = document.querySelector('.section__generic');
		this.resize();
		setTimeout(() => app.commons.Scroller.resize(), 0);
		window.addEventListener('resize', () => { this.resize(); });
	}
	reset() {
		this.$elm.forEach($block => {
			const $blockContent = $block.querySelector('.block__text__content'),
				$blockContentWrap = $block.querySelector('.block__text__content__wrap');
			$blockContent.style.width = null;
			$blockContentWrap.style.columns = null;

			let $first = $blockContentWrap.querySelector('*');
			if($first && !$blockContent.classList.contains('block__text__content--has-title')) $first.classList.add('first');
		});
	}
	resize() {
		this.reset();
		let coorsRoot = 0;
		this.$elm.forEach(($block, index) => {
			const $blockContent = $block.querySelector('.block__text__content'),
				$blockContentWrap = $block.querySelector('.block__text__content__wrap'),
				autoWidth = $blockContent.classList.contains('block__text__content--auto-width');

			coorsRoot = !coorsRoot ? $block.closest('.row--root').getBoundingClientRect().width * 0.28 : coorsRoot;

			let blockWidth = coorsRoot;
			blockWidth = ((this.isGeneric && $block.getAttribute('block-prev') === 'first' && index === 0) ? ((blockWidth*3)/1.5) : coorsRoot);
			$blockContent.style.width = blockWidth+'px';
				
			let coorsBlock = $block.getBoundingClientRect().height,
				coorsBlockContent = $blockContent.getBoundingClientRect().height,
				columns = Math.ceil(coorsBlockContent/coorsBlock),
				width = blockWidth * columns;

			if(!app.commons.Global.isBreakpoint) {
				$blockContent.style.width = width+'px';
				$blockContentWrap.style.columns = autoWidth ? 1 : columns;
			} else {
				$blockContent.style.width = null;
				$blockContentWrap.style.columns = null;
			}

			if(autoWidth) {
				if(!app.commons.Global.isBreakpoint) {
					let w = $blockContent.querySelector('.padd').getBoundingClientRect().width;

					$blockContent.style.width = w+'px';
					$blockContentWrap.style.columns = 1;
				} else {
					$blockContent.style.width = null;
					$blockContentWrap.style.columns = null;
				}
			}
		});
	}
}