import 'core-js';

export default class {
	constructor() {
		app.sections = app.sections || {}, app.sections.Header = this;
	}
	init() {
		const $logos  = document.querySelectorAll('.snippet__ivystudio'),
			$tagline = document.querySelector('.section__header__tagline');
		$tagline.addEventListener('click', (evt) => app.commons.Colors.random());
		$logos.forEach($elm => {
			const $a = $elm.querySelector('a');
			$a.addEventListener('click', (evt) => {
				if(app.commons.Nav.isSame($a.getAttribute('href'))) {
					evt.preventDefault();
					evt.stopPropagation();
				}
				//if(app.commons.Global.isBreakpoint) {
					//app.commons.Colors.random();
				//} else {
					app.commons.Colors.reset(app.commons.Colors.colorIntTxt, app.commons.Colors.colorIntBg);
				//}
			});
		});
	}
}