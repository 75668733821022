import 'core-js';

import { gsap } from 'gsap';

export default class {
	constructor() {
		app.commons = app.commons || {}, app.commons.Nav = this;
		this.init();
	}
	init() {
		document.querySelectorAll('.nav a').forEach($a => {
			$a.addEventListener('click', evt => {	
				if(this.isSame($a.getAttribute('href'))) {
					evt.preventDefault();
					evt.stopPropagation();
				}
			});
		});

		document.querySelectorAll('.nav__main > li').forEach($item => {
			const $childs = $item.querySelectorAll('ul > li li > a');
			$item.addEventListener('mouseenterCustom', evt => {
				if((!app.commons.Global.isMobile || evt.detail.force) && !!$childs.length) {
					$item.classList.toggle('over');
					if(!app.commons.Global.isMobile) document.body.classList.add('previewIsActive'); 
					$childs.forEach(($a, index) => {
						let delay = ($childs.length - index);
						if(!$a.tween) { $a.tween = gsap.timeline(); }
						$a.tween.clear().fromTo($a, 
							{x: -20, opacity: 0}, 
							{x: 0, opacity: 1, duration: 0.2}
						).delay(0.05 * delay).restart(true);
					});
				}
			});

			$item.addEventListener('mouseenter', evt => {
				if(!document.body.classList.contains('previewIsClicked') || !app.commons.Global.isMobile) {
					$item.dispatchEvent(new CustomEvent('mouseenterCustom', { detail: { force: false } }));
				}
			});

			$item.addEventListener('mouseleave', evt => {
				$item.classList.remove('over');
				if(!document.body.classList.contains('previewIsClicked') || app.commons.Global.isMobile) {
					this.leave();
				}
			});

			$item.addEventListener('click', evt => {
				if(!document.body.classList.contains('previewIsClicked') && app.commons.Global.isMobile) {
					$item.dispatchEvent(new CustomEvent('mouseenterCustom', { detail: { force: true } }));
				}
			});

			$childs.forEach($a => {
				if($a.dataset.previewSrc) app.commons.Preloader.load({'src': $a.dataset.previewSrc,'srcset': $a.dataset.previewSrcSet});

				$a.addEventListener('mouseenter', evt => {	
					if(!document.body.classList.contains('previewIsClicked') && !this.isSame($a.getAttribute('href'))) {
						if(!app.commons.Global.isMobile && $a.dataset.previewSrc && !this.isSame($a.getAttribute('href'))) {
							this.preview($a.dataset);
						} else if(app.commons.Global.isMobile || this.isSame($a.getAttribute('href'))) {
							this.leave();
						}
					}				
				});

				$a.addEventListener('click', evt => {
					if(!document.body.classList.contains('previewIsClicked') && !app.commons.Global.isMobile && !this.isSame($a.getAttribute('href'))) { 
						if($a.dataset.previewSrc) {
							document.querySelectorAll('.nav__main .current-menu-item').forEach($li => $li.classList.remove('current-menu-item'));
							$a.closest('li').classList.add('current-menu-item');
							if(!app.commons.Global.isMobile) { 
								document.body.classList.add('previewIsClicked');
							}
						}
					} else if(document.body.classList.contains('previewIsClicked')) {
						evt.preventDefault()
						evt.stopPropagation();
					}
				});
			});
		});
	}
	langs(en,fr) {
		document.querySelectorAll('.nav__langs,.nav__mobile').forEach($item => {
			let $fr = $item.querySelector('.nav__langs__lang--fr'),
			$en = $item.querySelector('.nav__langs__lang--en');
			if($en) $en.setAttribute('href', en ? en : window.display_vars.langs.en);
			if($fr) $fr.setAttribute('href', fr ? fr : window.display_vars.langs.fr);
		});
	}
	isSame(url) {
		if(url) {
			url = url.replace(/(^\w+:|^)\/\//, '');
			return new URL(window.location.protocol+url).pathname == window.location.pathname;
		}
	}
	preview(dataset) {
		let id = 'nav__main__preview',
			$isAlreadyHere = document.getElementById(id);
		if($isAlreadyHere) { $isAlreadyHere.remove(); }
		if(dataset && !app.commons.Global.isMobile) {
			document.body.classList.add('previewIsActive');

			let $previewContainer = document.createElement('div');
			$previewContainer.id = id;

			let $previewImage = document.createElement('img');
			$previewImage.src = dataset.previewSrc;
			$previewImage.srcset = dataset.previewSrcset;

			let html = '<div class="row row--root">\
				<div class="col col--8 offset--4">\
					'+$previewImage.outerHTML+'\
				<div>\
			</div>';

			$previewContainer.innerHTML = html;
			document.body.append($previewContainer);
		}
	}
	leave() {
		this.preview();
		document.body.classList.remove('previewIsActive');
		document.body.classList.remove('previewIsClicked');
	}
}