import 'core-js';

import LazyLoad from 'vanilla-lazyload';

export default class {
	constructor() {
		app.commons = app.commons || {}, app.commons.Lazy = this;
	}
	init() {
		this.lazyLoad = new LazyLoad({
			container: app.commons.Global.pageContainer,
			threshold: window.innerWidth,
		});
		this.update();
	}
	load($img) {
        if(!$img.getAttribute('data-ll-status')) { LazyLoad.load($img, null); }
	}
	loadAll() {
		this.lazyLoad.loadAll();
	}
	update() {
		this.lazyLoad.update();
	}
}