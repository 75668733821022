import 'core-js';

import isMobile from 'ismobilejs';
import FontFaceObserver from 'fontfaceobserver-es';

export default class {
	constructor() {
		app.commons = app.commons || {}, app.commons.Global = this;

		this.delay = 600;
		this.currentColors = 0;
		this.pageContainer = document.querySelector('[data-scroll-container]');
		this.colors = JSON.parse(window.display_vars.colors);

		this.base = getComputedStyle(document.documentElement).getPropertyValue('--base');
		this.padd = getComputedStyle(document.documentElement).getPropertyValue('--padd');
		this.baseReset = this.base;

		this.maxWidth = getComputedStyle(document.documentElement).getPropertyValue('--maxWidth');
		this.maxHeight = getComputedStyle(document.documentElement).getPropertyValue('--maxHeight');

		this.tablet = isMobile(window.navigator).tablet;
		this.phone = isMobile(window.navigator).phone;

		this.isBreakpoint = false;
		this.isMobile = this.tablet || this.phone;
		
		this.breakColIndex = 3;

		new FontFaceObserver('Roobert').load().then(() => window.dispatchEvent(new Event('resize')));

		window.addEventListener('resize', () => this.resize());
	}
	resize() {
		let minSize = 9,
			$body = document.body.classList,
			ratioSizes = (this.maxWidth/this.maxHeight),
			ratioWindow = (window.innerWidth/window.innerHeight),
			beakpointLabel = window.getComputedStyle(document.body, ':before').content.replace(/\"/g, ''),
			beakpointIndex = window.getComputedStyle(document.body, ':after').content.replace(/\"/g, ''),
			fontSize = this.base * ((ratioSizes > ratioWindow) ? (window.innerWidth/this.maxWidth) : (window.innerHeight/this.maxHeight));

		fontSize = this.base * (window.innerHeight/this.maxHeight);
		fontSize = fontSize < minSize ? minSize : fontSize;
		document.querySelector('html').style.fontSize = fontSize+'px';

		this.beakpoint = { label: beakpointLabel, index:( beakpointIndex !== '0' &&!parseInt(beakpointIndex)) ? 100 : parseInt(beakpointIndex) };
		this.portrait = window.innerWidth <= window.innerHeight;
		this.isBreakpoint = this.beakpoint.index <= this.breakColIndex;

		if(this.isBreakpoint && this.beakpoint.index < this.breakColIndex) { 
			if(this.beakpoint.index === 0) {
				document.querySelector('html').style.fontSize = this.baseReset -4+'px'; 
			} else {
				document.querySelector('html').style.fontSize = this.baseReset -2+'px'; 
			}
		}

		if(this.isMobile) {
			document.body.classList.add('is-mobile');
			document.body.classList.remove('is-desktop');
		} else {
			document.body.classList.add('is-desktop');
			document.body.classList.remove('is-mobile');
		}

		console.log('Current break point : ', this.beakpoint );
	}
}