export default class {
	constructor() { 
		app.commons = app.commons || {}, app.commons.Preloader = this;
	}
	load(sources, callback=false,callbackParams=[]) {
		sources = Array.isArray(sources) ? sources : (typeof sources == 'string' || typeof sources == 'object') ? [sources] : sources;
		const preload = $obj => new Promise((resolve, reject) => {
			let getSrc = null,
				getSrcset = null,
				getSizes = null;
			if($obj.nodeName == 'IMG' && !$obj.getAttribute('data-ll-status')) {
				getSrc = $obj.getAttribute('data-src');
				getSrcset = $obj.getAttribute('data-srcset') ? $obj.getAttribute('data-srcset') : false;
				getSizes = $obj.getAttribute('sizes') ? $obj.getAttribute('sizes') : false;
			} else if(typeof $obj == 'object') {
				getSrc = $obj.src;
				if($obj.srcset) { getSrcset = $obj.srcset; }
				if($obj.sizes) { getSizes = $obj.sizes; }
			} else {
				getSrc = $obj;
			}

			const img = new Image();
			img.onload = () => resolve(img);
			img.onerror = reject;

			img.src = getSrc;
			if(getSrcset) { img.srcset = getSrcset; }
			if(getSizes) { img.sizes = getSizes; }
		});

		this.preloadAll = sources => Promise.all( sources.map(preload) );

		this.preloadAll(sources)
			.then(images => {
				console.log('Preloaded all', images);
				if(callback && typeof callback === 'function') callback.apply(null, callbackParams);
			}).catch(err => console.error('Failed', err));
	}
}