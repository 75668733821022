import 'core-js';
import barba from '@barba/core';

export default class {
	constructor() {
		app.components = app.components || {}, app.components.TeamNav = this;
		this.current = {
			id: '',
			webm: '',
			mp4: '',
			loop: false,
			name: '',
			content: ''
		};
	}

	init() {
		if(app.commons.Global.isMobile) {
			document.querySelectorAll('.team__nav a').forEach($a => {
				$a.addEventListener('click', evt => {
					evt.preventDefault();
					evt.stopPropagation();
					barba.go(evt.target.href, 'click');
				});
			});
		} else {
			document.querySelectorAll('.team__nav a').forEach($a => {
				$a.addEventListener('mouseenter', evt => {
					const id = $a.id;
					const webm = $a.dataset.previewWebm || '';
					const mp4 = $a.dataset.previewMp4 || '';
					const loop = $a.dataset.videoLoop ? true : false;
					const name = $a.innerHTML;
					const content = $a.parentNode.querySelector('.team__nav__member__info').innerHTML;
					const isSame = this.current.id == id;

					if (!isSame) {
						this.preview(webm, mp4, loop, name, content);
					} else {
						this.leave();
					}
				});

				$a.addEventListener('click', evt => {
					const id = $a.id;
					const webm = $a.dataset.videoWebm || '';
					const mp4 = $a.dataset.videoMp4 || '';
					const loop = $a.dataset.videoLoop ? true : false;
					const name = $a.innerHTML;
					const content = $a.parentNode.querySelector('.team__nav__member__info').innerHTML;
					
					evt.preventDefault();
					evt.stopPropagation();

					if(!$a.parentNode.classList.contains('current-menu-item') && id) {
						this.set(id, webm, mp4, loop, name, content);
					}
				});
			});
			
			document.querySelectorAll('.team__nav').forEach($ul => {
				$ul.addEventListener('mouseleave', evt => {
					this.leave();
				});
			});
			
			document.querySelectorAll('.nav a').forEach($link => {
				$link.addEventListener('click', evt => {
					if (evt.target.pathname == window.location.pathname) window.location.reload();
				});
			});
		}
	}

	set(id, webm, mp4, loop, name, content) {
		const nameLine1 = name.substring(0, name.indexOf(' '));
		const nameLine2 = name.substring(name.indexOf(' ') + 1);
		this.current = { id, webm, mp4, name, content };

		document.querySelectorAll('.team__nav .current-menu-item').forEach($li => $li.classList.remove('current-menu-item'));
		document.querySelector(`.team__nav a[id="${this.current.id}"]`).parentNode.classList.add('current-menu-item');
		
		let $previewVideo = document.createElement('video');
		$previewVideo.classList.add('section__contact__content__video');
		$previewVideo.style.width = '100% !important';
		$previewVideo.style.height = 'auto !important';
		$previewVideo.preload = 'auto';
		$previewVideo.autoplay = true;
		$previewVideo.loop = loop;
		$previewVideo.muted = true;
		$previewVideo.playsinline = true;
		$previewVideo.disableremoteplayback = true;

		let $webmSource = document.createElement('source');
		$webmSource.src = webm;
		$webmSource.type = 'video/webm';
		$previewVideo.appendChild($webmSource);

		let $mp4Source = document.createElement('source');
		$mp4Source.src = mp4;
		$mp4Source.type = 'video/mp4';
		$previewVideo.appendChild($mp4Source);

		document.querySelector('.section_contact_col1').innerHTML = '<div class="section__contact__content__text padd">\
			<h1>\
			' + nameLine1 + '<br>\
			' + nameLine2 + '\
			</h1>\
			'+content+'\
		</div>';

		document.querySelector('.section_contact_col2').innerHTML = '<div class="section__contact__content__image padd">\
			'+$previewVideo.outerHTML+'\
		</div>';

		this.leave();

		document.querySelectorAll('video').forEach((vid) => {
			vid.muted = true;
			vid.playsinline = true;
			vid.play();
		});
	}

	preview(webm, mp4, loop) {
		let id = 'nav__team__preview';
		let $isAlreadyHere = document.getElementById(id);

		if($isAlreadyHere) $isAlreadyHere.remove();
		document.body.classList.remove('teamPreviewIsHover');

		if(webm && mp4 && !app.commons.Global.isMobile) {
			let $previewContainer = document.createElement('div');
			$previewContainer.id = id;

			let $previewVideo = document.createElement('video');
			$previewVideo.classList.add('section__contact__content__video');
			$previewVideo.style.width = '100% !important';
			$previewVideo.style.height = 'auto !important';
			$previewVideo.preload = 'auto';
			$previewVideo.autoplay = true;
			$previewVideo.loop = loop;
			$previewVideo.muted = true;
			$previewVideo.playsinline = true;
			$previewVideo.disableremoteplayback = true;

			let $webmSource = document.createElement('source');
			$webmSource.src = webm;
			$webmSource.type = 'video/webm';
			$previewVideo.appendChild($webmSource);
	
			let $mp4Source = document.createElement('source');
			$mp4Source.src = mp4;
			$mp4Source.type = 'video/mp4';
			$previewVideo.appendChild($mp4Source);

			let html = '<div class="row row--root">\
				<div class="col2 col col--6 offset--3">\
          <div class="section__contact__content__image padd">\
            '+$previewVideo.outerHTML+'\
          </div>\
				</div>\
			</div>';

			$previewContainer.innerHTML = html;
			document.body.append($previewContainer);
			document.querySelectorAll('#nav__team__preview video').forEach((vid) => {
				vid.muted = true;
				vid.playsinline = true;
				vid.play();
			});
		}
		
		document.body.classList.add('teamPreviewIsHover');
	}

	leave() {
		this.preview();
		document.body.classList.remove('teamPreviewIsHover');
	}
}