import 'core-js';

import 'locomotive-scroll/dist/locomotive-scroll.css';
import LocomotiveScroll from 'locomotive-scroll';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';



export default class {
	constructor() { 
		this.locomotive = false;
		app.commons = app.commons || {}, app.commons.Scroller = this;

		gsap.registerPlugin(ScrollTrigger);

		this.colorEnter = window.innerHeight*0.75;
		this.animEnter = window.innerHeight*0.33;
		this.appearEnter = window.innerHeight*0.25;
	}
	init() {
		ScrollTrigger.scrollerProxy(app.commons.Global.pageContainer, {
			scrollTop: (value) =>  {
				return !this.locomotive ? null : (
					arguments.length
						? this.locomotive.scrollTo(value, 0, 0)
						: this.locomotive.scroll.instance.scroll.y
					);
			},
			getBoundingClientRect() {
				return {
					top: 0, 
					left: 0, 
					width: window.innerWidth, 
					height: window.innerHeight
				};
			}
		});

		ScrollTrigger.addEventListener('refresh', () => { 
			if(this.locomotive) { this.locomotive.update(); }
		});

		this.build();
		/*
			//scroll speed test
			const wheelEvt = document.createEvent('MouseEvents');
			wheelEvt.initEvent('wheel', true, true);

			const speedTest = setInterval(() => {
				wheelEvt.deltaY = +10; 
				app.commons.Global.pageContainer.dispatchEvent(wheelEvt);
			}, 1);

			document.addEventListener('click', () => {
				clearInterval(speedTest);
				window.location.href = window.display_vars.siteurl;
			});
			//eof
		*/

		window.addEventListener('resize', () => this.resize());
	}
	build() {
		if(!app.commons.Global.isBreakpoint) {
			this.locomotive = new LocomotiveScroll({
				el: app.commons.Global.pageContainer,
				smooth: true,
				lerp: 0.05,
				multiplier: 0.5,
				scrollFromAnywhere: true,
				tablet: { smooth: true }
			});

			this.locomotive.on('scroll', () => ScrollTrigger.update());
			
			this.pins();
			this.colors();
			this.anims();
			this.appears();
			
			this.resize();
		}
	}
	pins(clear) {
		this.$sliders = document.querySelectorAll('[scroller-pin]');
		this.$sliders.forEach(($elm, index) => {
			let $pinWrap = $elm.querySelector('[scroller-pin-wrap]'),
				$pinMesure = $elm.querySelector('[scroller-pin-mesure]'),
				$pinWrapTarget = $elm.getAttribute('scroller-pin-wrap'),
				isHome = $elm.closest('.section__home-projects');
			if(!clear) {
				if($pinWrap) {
					if(!$pinMesure) {
						$pinMesure = document.createElement('div');
						$pinMesure.setAttribute('scroller-pin-mesure','');
						[...$pinWrap.children].map($child => $pinMesure.appendChild($child));
						$pinWrap.appendChild($pinMesure);
					}
					
					$pinWrapTarget = $pinWrapTarget ? $elm.querySelector($pinWrapTarget) : $pinMesure;

					let width = $pinMesure.getBoundingClientRect().width,
						offset = $pinWrapTarget.closest('.col').getBoundingClientRect().left,
						x = width + offset,
						xEnd = width*0.4;

					if(!$elm.scrollerTimeline) {
						$elm.scrollerTimeline = gsap.timeline({
							scrollTrigger: {
								pin: true,
								scrub: true,
								trigger: $elm,
								start: () => 'top top',
								invalidateOnRefresh: true,
								end: () => `bottom+=${((xEnd<window.innerWidth?window.innerWidth:xEnd)-window.innerWidth ) * (isHome ? 4 : 1)} top`,
								scroller: app.commons.Global.pageContainer,
								snap: (value) => window.innerHeight * (index+1),
								markers: false
							},
						});
					}
					$elm.scrollerTimeline.clear().fromTo($pinWrapTarget, { x: 0 }, { x: -x, duration: 1, ease: 'none' });
				}
			} else if(clear && $elm.scrollerTimeline) {
				$pinWrapTarget = $pinWrapTarget ? $elm.querySelector($pinWrapTarget) : $pinMesure;

				$elm.scrollerTimeline.kill();
				$elm.style.transform = null;
				$pinWrapTarget.style.transform = null;
				$elm.scrollerTimeline = false;
			}
		});
	}
	colors(clear) {
		this.$sliders = document.querySelectorAll('[scroller-pin]');
		this.$sliders.forEach(($elm, index) => {
			let colorTxt = $elm.getAttribute('scroller-pin-colortxt'),
				colorBg = $elm.getAttribute('scroller-pin-colorbg');

			if(!clear) {
				$elm.scrollerColor = ScrollTrigger.create({
					scrub: true,
					trigger: $elm,
					start: () => 'top+='+this.colorEnter+' bottom',
					end: () => 'bottom+='+this.colorEnter+' top',
					scroller: app.commons.Global.pageContainer,
					onEnter: () => {
						if(colorTxt || colorBg) { app.commons.Colors.set(colorTxt, colorBg); }
					},
					onEnterBack: () => {
						if(colorTxt || colorBg) { app.commons.Colors.set(colorTxt, colorBg); }
					},
					onLeave: () => {
						app.commons.Colors.reset();
					},
					onLeaveBack: () => {
						app.commons.Colors.reset();
					},
				});
			} else if(clear && $elm.scrollerColor) {
				app.commons.Colors.reset();
				$elm.scrollerColor = false;
			}
		});
	}
	anims(clear) {
		let pos = this.animEnter,
			direction = 'y';
		this.$anims = document.querySelectorAll('[scroller-anim]');
		this.$anims.forEach($elm => {
			let anim = null, 
				$pinWrap = $elm.closest('[scroller-pin]');
			$pinWrap = $pinWrap ? $pinWrap : $elm;

			if(!clear) {
				if($elm.classList.contains('[scroller-anim-fade]')) { pos = 0; }
				if($elm.classList.contains('[scroller-anim-fadetop]')) {}
				if($elm.classList.contains('[scroller-anim-fadebottom]')) { pos = pos*-1; }
				if($elm.classList.contains('[scroller-anim-fadeleft]')) { direction = 'x'; }
				if($elm.classList.contains('[scroller-anim-faderight]')) { direction = 'x'; pos = pos*-1; }

				if(direction == 'y') {
					anim = gsap.fromTo($elm, { y: pos, opacity: 0 }, { y: 0, opacity: 1 });
				} else {
					anim = gsap.fromTo($elm, { x: pos, opacity: 0 }, { x: 0, opacity: 1 });
				}

				$elm.scrollerAnim = ScrollTrigger.create({
					scrub: true,
					trigger: $pinWrap,
					start: 'top bottom',
					end: 'center center',
					scroller: app.commons.Global.pageContainer,
					animation: anim
				});
			} else if(clear && $elm.scrollerAnim) {
				$elm.style.opacity = null;
				$elm.style.transform = null;
				$elm.scrollerAnim = false;
			}
		});
	}
	appears(clear) {
		this.$appear = document.querySelectorAll('[scroller-appear]');
		this.$appear.forEach($elm => {
			let $pinWrap = $elm.closest('[scroller-pin]');
			$pinWrap = $pinWrap ? $pinWrap : $elm;

			if(clear) {
				$elm.scrollerAppear = ScrollTrigger.create({
					trigger: $pinWrap,
					start: () => 'top top+='+this.appearEnter,
					scroller: app.commons.Global.pageContainer,
					onEnter: () => $elm.setAttribute('scroller-appear-inview',''),
				});
			} else if(clear && $elm.scrollerAppear) {
				$elm.setAttribute('scroller-appear-inview','');
				$elm.scrollerAppear = false;
			}
		});
	}
	resize() {
		if(this.once) { clearTimeout(this.once); }
		this.once = setTimeout(() => {
			this.colorEnter = window.innerHeight*0.75;
			this.animEnter = window.innerHeight*0.33;
			this.appearEnter = window.innerHeight*0.25;
			if(app.commons.Global.isBreakpoint) { 
				this.destroy(); 
			} else {
				if(!this.locomotive) {
					this.build();
				} else {
					this.pins();
				}
			}
			this.once = null;
			this.refresh();
		});
	}
	refresh() {
		ScrollTrigger.update();
		ScrollTrigger.refresh();
	}
	destroy(pageloader) {
		if(this.locomotive || ScrollTrigger) {
			ScrollTrigger.getAll().forEach(trigger => trigger.kill());
			if(!pageloader) {
				if(this.locomotive) {
					this.locomotive.destroy();
					this.locomotive = false;
				}
				this.pins(true);
				this.colors(true);
				this.anims(true);
				this.appears(true);
			}
		}
	}
	scrollTo($target, duration) {
		if(this.locomotive && !app.commons.Global.isBreakpoint) {
			
			let $hideThisScrolling = app.commons.Global.pageContainer.querySelector('div');
			if(app.commons.Global.isMobile) $hideThisScrolling.style.opacity = 0;

			this.locomotive.scrollTo($target, {
				duration: !duration ? 0.1 : duration, 
				disableLerp: true,
				callback: () => $hideThisScrolling.style.opacity = null
			});
		}
	}
	getScrollPos() {
		if(this.locomotive) {
			return this.locomotive.scroll.instance.scroll.y;
		}
	}
	start() {
		if(this.locomotive) {
			this.locomotive.start();
		}
	}
	stop() {
		if(this.locomotive) {
			this.locomotive.stop();
		}
	}
}